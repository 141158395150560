<template>
  <div>
    <Loader />
    <section class="sign-in-page">

      <div class="bg-login">

        <div class="sign-in">
          <div class="sign-in-item">
            <div class="start-section-content">
              <img src="../../assets/images/Dafe-logo-01.png" alt="" class="pb-4">
              <h3 class="text-center pb-4 title-dafa ">Dafa Payment aggregator</h3>
              <p class="text-sign-in-page">A completely unified checkout and payments integration, DPA lets you truly consolidate your payments stack, plus add APIs and tools from across the web to build dynamic end-to-end payment flows for the first time.</p>
            </div>
          </div>

          <div class="sign-in-item">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import Loader from '../../components/core/loader/Loader'
import logo from '../../assets/images/logo-white.png'
import { core } from '../../config/pluginInit'
import bgImage from '../../assets/images/login/login-2.jpg'
// import Swiper from '../../components/core/slider/Swiper'
// import SwiperSlide from '../../components/core/slider/SwiperSlide'
export default {
  name: 'AuthLayout',
  components: {
    Loader
    // Swiper,
    // SwiperSlide
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      options: {
        slidesPerView: 1,
        loop: false
      },
      logo: logo,
      bgImageURL: bgImage
    }
  }
}
</script>
<style lang="scss">
@import url("../../assets/css/custom.css");
@import url("../../assets/css/auth.css");

.sign-in-page{
  background-color: rgba(26, 25, 25, 0.911);
  width: 100%;
  .bg-login{
    background-image: url(../../assets/images/login/bg-home.png);
    background-color: rgba(0, 0, 0, 0.58);
    background-attachment: fixed;
    background-size: cover;
  }
  .sign-in {
    background-color: rgba(0, 0, 0, 0.4);
    height: 100%;
    min-height: 100vh;
    background-attachment: fixed;
    display: flex;
    flex-wrap: wrap;
    .sign-in-item {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      @media (max-width: 1200px) {
        width: 100%;
      }
    }

    .start-section-content {
      color: white;
      text-align: center;
      padding: 5%;
      img{
        width: 35%;
      }
    }
  }
}

.text-sign-in-page{
  max-width: 560px;
  margin: auto;
  text-align: center;
}
.title-dafa{
  color: white;
  font-size:30px;
  font-weight:700;
  text-align: center;
}

</style>
